import styled from 'styled-components'

export const ContainerProfile = styled.div`
  display: flex;
  align-items: center;
  gap: 0.75rem;

  @media only screen and (${({ theme }) => theme.device.tablet}) {
    h3.complete-name {
      display: none;
    }
  }
`

export const AvatarName = styled.div`
  width: 36px;
  height: 36px;
  background-color: rgb(236, 0, 140);
  position: relative;
  border-radius: 50%;

  span.initial {
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 3px;
    left: 1px;
    font-size: 28px;
    text-align: center;
    transform: matrix(0.98, -0.17, 0.17, 0.98, 0, 0);
    font-family: ${({ theme }) => theme.font.family.lilita};
    color: ${({ theme }) => theme.colors.white};
  }
`

export const AvatarButtom = styled.button`
  background: transparent;
  border: 0;
  cursor: pointer;

  span.initial {
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 5px;
    left: 2px;
    font-size: 24px;
    transform: matrix(0.98, -0.17, 0.17, 0.98, 0, 0);
    font-family: ${({ theme }) => theme.font.family.lilita};
    color: ${({ theme }) => theme.colors.white};
  }
`
