import { getSession } from 'core/services/storage'
import { handleResponse } from './handlers/handleResponse'
import { handlerParams } from './handlers/handlerParams'
import { CompanyApiModel } from '../model/company-api-model'

type UseFetchProps = {
  method: 'get' | 'post' | 'put' | 'delete'
  url: string
  baseUrl: string
  body?: unknown
  params?: object
  cors?: boolean
  isAuthenticated?: boolean
}

type Token = {
  AccessToken: string
  RefreshToken: string
}

function authHeader(isAuthenticated = true) {
  if (!isAuthenticated) return
  const tokenUser = getSession<Token>(
    process.env.REACT_APP_TOKEN_LOCALSTORAGE || ''
  )
  const tokenCustomer = getSession<Token>(
    process.env.REACT_APP_TOKEN_CLIENT_LOCALSTORAGE || ''
  )
  if (tokenUser?.AccessToken) {
    return { Authorization: `Bearer ${tokenUser.AccessToken}` }
  } else if (tokenCustomer.AccessToken) {
    return { Authorization: `Bearer ${tokenCustomer.AccessToken}` }
  } else {
    return
  }
}

function companyHeader() {
  return { company: process.env.REACT_APP_CADASTRO_EMPRESA_ID || '' }
}

function apiKeyHeader() {
  return { 'x-api-key': process.env.REACT_APP_CADASTRO_X_API_KEY || '' }
}

function storeHeader() {
  const userLogged = getSession<CompanyApiModel>(
    process.env.REACT_APP_USER_LOCALSTORAGE || ''
  )
  if (userLogged?.data) {
    return { store: userLogged.data.Loja.toString() }
  }
  return
}

export default function apiFetch<T>({
  method,
  baseUrl,
  url,
  params,
  body,
  cors,
  isAuthenticated = true
}: UseFetchProps) {
  const requestOptions: RequestInit = {
    method,
    headers: {
      'Content-Type': 'application/json',
      ...authHeader(isAuthenticated),
      ...companyHeader(),
      ...apiKeyHeader(),
      ...storeHeader()
    },
    mode: cors ? 'no-cors' : 'cors'
  }
  if (body) requestOptions.body = JSON.stringify(body)

  return fetch(
    handlerParams(
      `${!baseUrl ? process.env.REACT_APP_URL : baseUrl}${url}`,
      params
    ),
    requestOptions
  )
    .then(handleResponse<T>)
    .catch((error) => {
      return { error, success: false, status: error.status, data: null }
    })
}
