export const icons = {
  copy: require('core/assets/icons/copy.svg'),
  clear: require('core/assets/icons/clear.svg'),
  search: require('core/assets/icons/search.svg'),
  edit: require('core/assets/icons/edit.svg'),
  trash: require('core/assets/icons/trash.svg'),
  arrowLeft: require('core/assets/icons/arrow-left.svg'),
  arrowDownSelect: require('core/assets/icons/arrow_down_select.svg'),
  categoria_happy: require('core/assets/icons/categoria_happy.svg'),
  categoria_super_happy: require('core/assets/icons/categoria_super_happy.svg'),
  categoria_top_happy: require('core/assets/icons/categoria_top_happy.svg'),
  alert: require('core/assets/icons/alert.svg')
}
