import { useContext, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { SearchModel } from './models/search-model'
import z, { validateZod } from 'core/components/form/zod'
import { getSession } from 'core/services/storage'
import { notify } from 'core/services/notification'

import * as Styled from './styles'
import Input from '../form/input'
import { icons } from 'core/assets'
import Form from '../form'
import { destroySearchedCpf } from 'core/services/storage/localStorage/destroySearchedCpf'
import { getSearchedCpf } from 'core/services/storage/localStorage/getSeachedCpf'
import Spinner from '../spinner'
import { CompanyApiModel } from 'core/services/model/company-api-model'
import { destroyCustomer } from 'core/services/storage/localStorage/destroyCustomer'
import { cpfValidator } from 'core/utils/cpf-validator'
import { CustomerContext } from 'core/contexts/CustomerManagerContext'

export interface User {
  email: string
  id: number
  nome: string
  senha: string
  token: string
}
export function SearchBox() {
  const { getCustomer, updateTypedCpf, loadCustomer } =
    useContext(CustomerContext)

  const [userLogged, setUserLogged] = useState<CompanyApiModel | null>(null)

  const navigate = useNavigate()

  useEffect(() => {
    const user = getSession<CompanyApiModel>(
      process.env.REACT_APP_USER_LOCALSTORAGE || ''
    )
    if (user?.data) setUserLogged(user)
  }, [])

  const cpfRegex = new RegExp(/^\d{3}\.\d{3}\.\d{3}-\d{2}$|^\d{11}$/)
  const serachedCpf = getSearchedCpf()
  const form = useForm<SearchModel>({
    defaultValues: {
      cpf: serachedCpf || ''
    },
    ...validateZod({
      cpf: z
        .string()
        .regex(cpfRegex, 'CPF invalido')
        .refine((cpf) => cpfValidator(cpf), { message: 'CPF inválido' })
    })
  })

  const cpfField = form.watch().cpf
  useEffect(() => {
    updateTypedCpf(cpfField)
  }, [cpfField, updateTypedCpf])
  async function onSubmit(payload: SearchModel) {
    if (userLogged?.data) {
      await getCustomer(payload.cpf, true)
    } else {
      await getCustomer(payload.cpf)
    }

    form.setValue('cpf', payload.cpf)
  }

  function copycontent() {
    const campoTexto = document.getElementById('cpf-search') as HTMLInputElement
    navigator.clipboard
      .readText()
      .then((text) => {
        if (cpfRegex.test(text)) {
          campoTexto.value = text
          onSubmit({ cpf: text })
        } else {
          return
        }
      })
      .catch(() => {
        notify.error('Permissão negada para colar texto!')
      })
    campoTexto.focus()
  }

  function navigateToStart() {
    if (!userLogged) {
      form.setValue('cpf', '')
    } else {
      destroySearchedCpf()
      destroyCustomer()
      form.setValue('cpf', '')
      navigate('/')
    }
  }
  return (
    <Styled.ContainerSearch userLogged={!!userLogged}>
      <Form {...form} onSubmit={form.handleSubmit(onSubmit)}>
        <Input
          name='cpf'
          mask='999.999.999-99'
          maskPlaceholder={null}
          autoComplete='off'
          placeholder={
            userLogged?.data ? 'Informe o CPF do cliente' : 'Informe seu CPF'
          }
          id='cpf-search'
        />

        {!form.formState.errors?.cpf && (
          <button
            type='button'
            className='actions'
            onClick={(e) => {
              e.preventDefault()
              destroySearchedCpf()
              navigateToStart()
            }}
          >
            {loadCustomer ? (
              <Spinner text='Aguarde...' size={18} color='purple' />
            ) : (
              <img
                src={
                  cpfField.length ? icons.clear.default : icons.search.default
                }
                alt='ícone'
              />
            )}
          </button>
        )}
        {userLogged?.data && (
          <button
            type='button'
            className='copy'
            onClick={(e) => {
              e.preventDefault()
              copycontent()
            }}
          >
            <img src={icons.copy.default} alt='ícone' />
          </button>
        )}
      </Form>
    </Styled.ContainerSearch>
  )
}
