import styled, { css } from 'styled-components'
import { media } from 'core/assets/images/media'

export const Container = styled.section`
  display: flex;
  flex-wrap: wrap;
  min-height: calc(100vh - 60px);
  background-size: cover;
  background-color: ${({ theme }) => theme.colors.whiteBg};
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  overflow: hidden;
`

export const ContainerHead = styled.div`
  position: absolute;
  top: -230px;
  width: calc(100% + 200px);
  margin-left: -100px;
  height: 701px;
  transform: rotate(-10deg);
  background-color: ${({ theme }) => theme.colors.light};
  border-bottom: 10px solid ${({ theme }) => theme.colors.white};
`
export const Decorarion4 = styled.div`
  width: 92px;
  height: 117px;
  position: absolute;
  top: 100px;
  left: 10px;
  z-index: 2;

  &:before {
    content: '';
    background: url(${media.decoration4});
    background-size: contain;
    display: block;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
  }
`

export const Content = styled.div`
  z-index: 2;
  width: 100%;
  position: relative;
`

export const ContainerLogo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  align-items: baseline;
  margin: auto;
`

export const ContainerCardAuth = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 400px;
  margin: auto 3rem auto auto;
  padding: 3rem 2rem;
  border-radius: 25px;
  width: 550px;

  ${({ theme }) => css`
    background-color: ${theme.colors.white};

    @media ${theme.device.laptop} {
      margin: auto auto auto auto;
    }

    @media ${theme.device.tablet} {
      width: 450px;
      padding: 2rem;
    }
  `}
`
