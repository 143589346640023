export const media = {
  mascote: require('core/assets/images/media/mascote.png'),
  mascote2: require('core/assets/images/media/mascote2.png'),
  decoration1: require('core/assets/images/media/decoration1.svg'),
  decoration2: require('core/assets/images/media/decoration2.png'),
  decoration3: require('core/assets/images/media/decorarion3.png'),
  decoration4: require('core/assets/images/media/decoration4.png'),
  solzinho: require('core/assets/images/media/solzinho.png'),
  nerf: require('core/assets/images/media/nerf.webp'),
  pjmask: require('core/assets/images/media/pjmask.webp'),
  hotwheels: require('core/assets/images/media/hotwheels.png'),
  provisorio1: require('core/assets/images/media/provisorio1.png'),
  provisorio2: require('core/assets/images/media/provisorio2.png'),
  provisorio3: require('core/assets/images/media/provisorio3.png'),
  provisorio4: require('core/assets/images/media/provisorio4.png'),
  cashback: require('core/assets/images/media/cashback.png'),
  cashback_v2: require('core/assets/images/media/cashback_v2.png'),
  desconto_aniversario: require('core/assets/images/media/desconto_aniversario.png'),
  frete_gratis: require('core/assets/images/media/frete_gratis.png'),
  beneficios: require('core/assets/images/media/beneficios.png'),
  seis_meses: require('core/assets/images/media/seis_meses.png'),
  parcelamento: require('core/assets/images/media/parcelamento.png'),
  birthday: require('core/assets/images/media/birthday.png'),
  payment: require('core/assets/images/media/payment.svg'),
  offers: require('core/assets/images/media/offers.svg'),
  smile: require('core/assets/images/media/smile.png'),
  sad: require('core/assets/images/media/sad.png'),
  ico_rh: require('core/assets/images/media/ico_rh_fill.png')
}
