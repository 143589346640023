import styled from 'styled-components'

export const Error = styled.span`
  display: block;
  width: 100%;
  font-size: 0.6875rem;
  font-family: ${({ theme }) => theme.font.family.opensans};
  color: ${({ theme }) => theme.colors.red};
  text-align: right;
  position: relative;
  top: calc(100% + 3px);
`
