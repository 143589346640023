import 'styled-components'

export default {
  grid: {
    container: '130rem',
    gutter: '3.2rem'
  },
  border: {
    radius: '0.4rem'
  },
  font: {
    family: {
      lilita:
        "Lilita, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif",
      opensans:
        "OpenSans, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif"
    },
    light: 300,
    normal: 400,
    bold: 600,
    sizes: {
      xxsmall: '.8125rem',
      xsmall: '.875rem',
      small: '1rem',
      medium: '1.625rem',
      xmedium: '1.5rem',
      large: '2rem',
      xlarge: '3rem',
      xxlarge: '4rem',
      huge: '5.2rem'
    }
  },
  colors: {
    primary: '#62479d',
    secondary: '#ec008c',
    white: '#ffffff',
    whiteBg: '#f9f6f6',
    black: '#010101',
    light: '#fd0',
    greyLight: '#f1f1f1',
    green: '#2DA949',
    greenButton: '#2CB34A',
    orange: '#EF5728',
    blue: '#1CB7EB',
    dark: '#181C32',
    purple: '#62489D',
    darkPurple: '#301753',
    lightPurple: '#b6a5e0',
    grey: '#3e4a61',
    border: '#e3e3e3',
    borderInput: '#C4B7B7',
    borderCard: '#F0F0F0',
    inputColor: '#3C3C3C',
    inputActive: '#3C3C3C',
    inputDisabled: '#DEDEDE',
    mainBg: '#1E1E2D',
    red: '#EA2926',
    'gray-300': '#C4C4CC'
  },
  spacings: {
    quark: '0.25rem',
    nano: '0.5rem',
    xxxs: '1rem',
    xxs: '1.5rem',
    xs: '2rem',
    sm: '2.5rem',
    md: '3rem',
    lg: '3.5rem',
    xl: '4rem',
    xxl: '5rem',
    xxxl: '7.5rem',
    huge: '10rem',
    giant: '12.5rem'
  },
  device: {
    mobileS: '(max-width: 320px)',
    mobileM: '(max-width: 375px)',
    mobileL: '(max-width: 480px)',
    tablet: '(max-width: 768px)',
    tabletL: '(max-width: 900px)',
    laptop: '(max-width: 1024px)',
    laptopL: '(max-width: 1440px)',
    desktop: '(max-width: 2560px)'
  },
  layers: {
    base: 10,
    menu: 20,
    overlay: 30,
    modal: 40,
    alwaysOnTop: 50
  },
  transition: {
    default: '0.3s ease-in-out',
    fast: '0.1s ease-in-out'
  }
} as const
