import { Menu, MenuItem, useMediaQuery } from '@mui/material'
import * as Styled from './styles'
import { useContext, useState } from 'react'
import { CustomerContext } from 'core/contexts/CustomerManagerContext'
import { destroySession, getSession } from 'core/services/storage'
import { ExitToApp } from '@mui/icons-material'
import { CompanyApiModel } from 'core/services/model/company-api-model'
import { useNavigate } from 'react-router-dom'

interface ProfileProps {
  name: string
}
function Profile({ name }: ProfileProps) {
  const matches = useMediaQuery('(max-width: 768px)')
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const { logoutCustomer } = useContext(CustomerContext)
  const navigate = useNavigate()

  function logout() {
    console.log('Logout lojista')
    navigate('/login')
    destroySession()
  }

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  function formatName(name: string): string {
    const splittedName = name?.split(' ')
    if (splittedName.length > 1) {
      return `${splittedName[0]} ${splittedName[splittedName.length - 1]}`
    } else {
      return splittedName[0] || ''
    }
  }
  function flowLogout() {
    const user = getSession<CompanyApiModel>(
      process.env.REACT_APP_USER_LOCALSTORAGE || ''
    )
    if (user.data) {
      logout()
    } else {
      logoutCustomer()
    }
  }
  return (
    <Styled.ContainerProfile>
      {matches ? (
        <>
          <Styled.AvatarButtom
            id='basic-button'
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup='true'
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
          >
            <Styled.AvatarName>
              <span className='initial'>{name.charAt(0).toUpperCase()}</span>
            </Styled.AvatarName>
          </Styled.AvatarButtom>
          <Menu
            id='basic-menu'
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button'
            }}
          >
            <MenuItem onClick={() => flowLogout()}>
              <ExitToApp /> Sair
            </MenuItem>
          </Menu>
        </>
      ) : (
        <Styled.AvatarName>
          <span className='initial'>{name.charAt(0)}</span>
        </Styled.AvatarName>
      )}
      <h3 className='complete-name'>{formatName(name)}</h3>
    </Styled.ContainerProfile>
  )
}

export default Profile
