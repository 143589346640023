import styled, { css } from 'styled-components'
import { default as InputMaskLib } from 'react-input-mask'
import theme from 'core/styles/theme'
export interface ErrorProps {
  errorField: string | undefined
  disabled: boolean
}

export const Container = styled.div<ErrorProps>`
  position: relative;

  .position-error {
    position: absolute;
  }
  & input {
    border-color: ${(props) =>
      !props.errorField
        ? ({ theme }) => theme.colors.inputDisabled
        : theme.colors.red};
  }
  &.disabled {
    position: relative;
    &::before {
      z-index: 99;
      top: 0;
      left: 0;
      position: absolute;
      content: '';
      display: block;
      width: 100%;
      height: 48px;
      border-radius: 0.675rem;
      background: rgba(0, 0, 0, 0.1);
    }
  }
  label {
    font-weight: bold;
  }
  span.no-error {
    display: none;
  }
  span.error {
    position: absolute;
    display: inline-block;
    width: 20px;
    height: 20px;
    left: calc(100% - 35px);
    top: 50%;

    img {
      display: ${(props) => (props.disabled ? 'none' : 'initial')};
    }
  }
`
export const InputCss = css`
  margin: 0;
  width: 100%;
  height: 3rem;
  font-size: ${({ theme }) => theme.spacings.xxxs};
  font-weight: 500;
  line-height: 1.5;
  appearance: none;
  box-shadow: none;
  padding: 0.63rem 0.82rem;
  border-radius: ${({ theme }) => theme.spacings.quark};
  background-clip: padding-box;
  background: ${({ theme }) => theme.colors.white};
  font-family: ${({ theme }) => theme.font.family.opensans};
  border: 1px solid ${({ theme }) => theme.colors.inputDisabled};
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  color: ${({ theme }) => theme.colors.inputColor};
  position: relative;

  &:placeholder {
    opacity: 0.1;
  }
  &:focus {
    outline: none;
    border: 1px solid ${({ theme }) => theme.colors.inputActive};
  }

  &:hover {
    border: 1px solid ${({ theme }) => theme.colors.inputActive};
  }

  &:disabled {
    border: 1px solid ${({ theme }) => theme.colors.inputDisabled};
    background-color: ${({ theme }) => theme.colors.greyLight};
  }

  &-webkit-autofill::first-line {
    font-family: ${({ theme }) => theme.font.family};
    font-size: 1rem;
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    -webkit-text-fill-color: ${({ theme }) => theme.colors.inputColor};
    transition: background-color 5000s ease-in-out 0s;
  }
`
export const InputMask = styled(InputMaskLib)`
  ${InputCss}
  &.disabled {
    color: ${({ theme }) => theme.colors['gray-300']}!important;
  }
  &.textSearch {
    padding-left: 2.4rem !important;
  }
`
