export const encode = (data: string) => {
  const buffer: string = window.btoa(
    `${data || ''}${process.env.REACT_APP_KEY_LOCALSTORAGE}`
  )
  return buffer
}

export const decode = (data: string) => {
  const buffer: string = window
    .atob(data)
    .replace(process.env.REACT_APP_KEY_LOCALSTORAGE || '', '')
  return buffer
}
