import { createGlobalStyle, css } from 'styled-components'

import {
  LilitaRegular,
  OpenSansBold,
  OpenSansSemiBold
} from 'core/assets/fonts'
import margin from './margin'
import padding from './padding'
import text from './text'

export const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'Lilita';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local('Lilita Regular'), local('Lilita-Regular'),
        url(${LilitaRegular}) format('woff2');
  }

  @font-face {
    font-family: 'OpenSans';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: local('OpenSans SemiBold'), local('OpenSans-SemiBold'),
        url(${OpenSansSemiBold}) format('woff2');
  }

  @font-face {
    font-family: 'OpenSans';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: local('OpenSans Bold'), local('OpenSans-Bold'),
        url(${OpenSansBold}) format('woff2');
  }

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  html, body {
    height: 100%;
    -webkit-font-smoothing: antialiased;
  }

  ${({ theme }) => css`
    body {
      font-family: ${theme.font.family.lilita};
      font-size: ${theme.font.sizes.xxsmall};
      font-weight: ${theme.font.normal};
    }
    label,
    button,
    input {
      font-family: ${theme.font.family.opensans};
    }
  `}

  ${margin}

  ${padding}
  
  ${text}
`
