import { Container } from './styles'
import Place from '@mui/icons-material/Place'
import { logos } from '../../assets/images/logos'
import { ExitToApp } from '@mui/icons-material'
import { useContext, useEffect, useState } from 'react'
import { destroySession, getSession } from 'core/services/storage'
import { CompanyApiModel } from 'core/services/model/company-api-model'
import { CustomerContext } from 'core/contexts/CustomerManagerContext'
import Profile from '../profile'
import { Menu, MenuItem, useMediaQuery } from '@mui/material'
import { useNavigate } from 'react-router-dom'

interface Headerprops {
  hasCpfField: boolean
}

export const Header = ({ hasCpfField }: Headerprops) => {
  const [session, setSession] = useState<CompanyApiModel | null>(null)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const matches = useMediaQuery('(max-width: 768px)')
  const navigate = useNavigate()

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const { logoutCustomer, customerLogged, customerCtx } =
    useContext(CustomerContext)

  useEffect(() => {
    const user = getSession<CompanyApiModel>(
      process.env.REACT_APP_USER_LOCALSTORAGE || ''
    )
    if (user?.data) setSession(user)
  }, [])

  function logout() {
    navigate('/login')
    setSession(null)
    destroySession()
  }

  function flowLogout() {
    const user = getSession<CompanyApiModel>(
      process.env.REACT_APP_USER_LOCALSTORAGE || ''
    )
    if (user.data) {
      logout()
    } else {
      logoutCustomer()
    }
  }

  return (
    <Container className={hasCpfField ? 'has-cpf-field' : ''}>
      <span className='logo'>
        <img src={logos.logo.default} alt='Logotipo' />
      </span>
      {(session || (customerLogged && customerCtx)) && (
        <nav>
          {session && (
            <>
              {matches ? (
                <>
                  <span
                    className='city'
                    id='basic-button'
                    aria-controls={open ? 'basic-menu' : undefined}
                    aria-haspopup='true'
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick}
                  >
                    <Place fontSize='small' />
                    {session?.data?.Dsc}
                  </span>
                  <Menu
                    id='basic-menu'
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                      'aria-labelledby': 'basic-button'
                    }}
                  >
                    <MenuItem onClick={() => flowLogout()}>
                      <ExitToApp /> Sair
                    </MenuItem>
                  </Menu>
                </>
              ) : (
                <span className='city'>
                  <Place fontSize='small' />
                  {session?.data?.Dsc}
                </span>
              )}
            </>
          )}
          {!session && customerLogged && customerCtx && (
            <Profile name={customerCtx?.Nome + ' ' + customerCtx?.SobreNome} />
          )}

          <span onClick={() => flowLogout()} className='logout'>
            <ExitToApp />
            Sair
          </span>
        </nav>
      )}
    </Container>
  )
}
